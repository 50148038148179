.restaurant-modal {
  min-height: 60vh;
}
.restaurant-modal.blur .background-blur {
  filter: blur(5px);
}
.restaurant-modal-container {
  width: 80%;
  margin: auto;
  border-bottom: 1px solid rgb(0, 0, 0);
  padding: 1%;
}
.restaurant-modal-container h1 {
  font-weight: 900;
  color: black;
}
.restaurant-image {
  width: 100%;
  height: 305px;
}
.restaurant-cuisines {
  display: flex;
  gap: 20px;
}
.restaurant-modal-container .restaurant-details {
  font-family: "Arial", sans-serif;
  line-height: 1.5;
}
.business-phone {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.eta {
  font-size: 14px;
  color: #555;
  margin: 8px 0;
}
.note {
  font-size: 12px;
  color: #888;
  font-style: italic;
  margin-top: 8px;
}
.restaurant-modal-container .restaurant-name {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.restaurant-details-flex {
  display: flex;
  gap: 20px;
}
.restaurant-modal-container .column-choice {
  display: flex;
  gap: 20px;
  padding-top: 4%;
}
.restaurant-modal-container .column-choice p:hover {
  transition: 0.2s;
  cursor: pointer;
  background-color: rgb(213, 213, 213);
}
.restaurant-modal .categories-flex {
  display: flex;
  margin-top: 1.8%;
  width: 80%;
  padding: 1.5% 0;
  margin: auto;
}
.restaurant-modal .menu-categories {
  padding-left: 0;
  padding-top: 2%;
  margin-right: 2%;
  width: 18%;
  border-right: 1px solid;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.restaurant-modal .categories-display {
  width: 100%;
}
.menu-categories-intersecting {
  padding-top: 2%;
  margin: 1%;
  border-right: 1px solid;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: sticky;
  top: 120px;
}
.menu-categories li:hover {
  background-color: rgb(213, 213, 213);
  cursor: pointer;
  transition: 0.2s;
}
.menu-categories-intersecting li:hover {
  background-color: rgb(213, 213, 213);
  cursor: pointer;
  transition: 0.2s;
}
.menu-categories li:active {
  background-color: rgb(213, 213, 213);
}
.menu-item-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.hover:hover {
  cursor: pointer;
}
.menu-item-price span {
  border: 1px solid red;
  background-color: red;
  color: white;
  padding: 1.5%;
}
.categories-flex .menu-categories li {
  width: 100%;
  color: black;
}
.categories-display-wrapper .headline {
  margin-bottom: 2%;
}
.categories-display-wrapper h2 {
  font-size: x-large;
  font-weight: 900;
  color: black;
}
.categories-display-wrapper {
  padding: 1%;
}
.menu-category {
  margin-bottom: 5%;
}
.menu-category-items {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1rem;
  padding: 0.5rem;
}
.menu-display {
  margin: auto;
  overflow-x: scroll;
  margin-bottom: 2.5%;
}
.menu-display-wrapper-items {
  margin-bottom: 10px;
}
.menu-display-wrapper {
  display: flex;
}
.menu-item {
  display: flex;
}
.menu-display .category-type {
  padding: 2% 0;
  font-size: 1.6rem;
  color: black;
  font-weight: 900;
}
.menu-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 2px solid;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 1%;
}
.menu-item-wrapper .img-container {
  height: auto;
  min-width: 200px;
  max-width: 200px;
  margin: auto;
  text-align: center;
}
.menu-item-wrapper .img-container img {
  height: 150px;
  width: 150px;
}
.menu-item-wrapper p {
  padding-left: 2%;
  text-align: center;
}
.menu-categories-intersecting li {
  margin: 2%;
}
.modal-overlay {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  display: flex;
  margin-top: 5%;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.modal-content h2 {
  color: black;
  font-size: x-large;
  font-weight: 900;
  padding: 5% 0;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.modal-item-options {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  margin-top: 2%;
  border-top: 1px solid;
}
.item-quantity {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  font-size: larger;
}
.item-quantity span:hover {
  cursor: pointer;
}
.modal-item-options button {
  padding: 2% 10%;
  border-radius: 10px;
  background-color: royalblue;
  color: whitesmoke;
  font-weight: 900;
}
.modal-item-options button:hover {
  cursor: pointer;
}
.item-instructions textarea {
  width: 100%;
  padding: 1.5%;
  margin-top: 1.5%;
}
.item-instructions p {
  color: black;
}
#customerRequests {
  padding: 1.5%;
  margin: 1.8% 0;
}
.modal-content p span {
  color: black;
  font-weight: 900;
}
.modal-content img {
  object-fit: none;
  margin: auto;
  height: 300px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.restaurant-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.restaurant-hours li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 16px;
}
.status {
  font-weight: bold;
}
.open {
  color: green;
}
.closed {
  color: red;
}
.image-carousel {
  width: 100%;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
}
.carousel-image {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .restaurant-modal-container {
    width: 90%;
  }
  .restaurant-modal-container .column-choice {
    flex-direction: column;
  }
  .restaurant-cuisines {
    flex-direction: column;
  }
  .menu-category {
    margin-bottom: 5%;
  }
  .menu-category-items {
    overflow-x: scroll;
  }
  .restaurant-modal .menu-categories,
  .menu-categories-intersecting {
    width: 100%;
    border-right: none;
  }
  .menu-item {
    display: flex;
  }
  .modal-content {
    max-width: 90%;
    margin-top: 17%;
  }
  .menu-item-wrapper {
    width: 100%;
  }
  .categories-display-wrapper {
    margin-top: 5%;
  }
  .restaurant-modal .modal-content img {
    object-fit: contain;
    max-width: 250px;
    min-width: 250px;
    height: 200px;
  }
  .restaurant-modal .modal-overlay {
    margin-top: 10%;
  }
  .categories-display-wrapper .image-1 {
    height: 260px;
  }
  .categories-display-wrapper .image-2 {
    height: 260px;
  }
  .restaurant-modal .categories-flex {
    width: 100%;
  }
  .menu-item-price {
    width: 100px;
  }
}
