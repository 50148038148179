.profile-categories {
  width: 100%;
  display: flex;
}
.profile-categories-container {
  border-right: 1px solid;
  width: 20%;
  height: 100vh;
  min-height: 100%;
}
.categories {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 40%;
  padding-top: 5%;
  padding-left: 8%;
}
.categories li:hover {
  cursor: pointer;
}
.profile-category {
  width: 100%;
  margin: 10px;
  min-height: 100vh;
}
.user-info {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  color: #333;
}
.user-info-container {
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.user-info-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.user-info-container-flex h2 {
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s;
}
.user-info-container-flex h2:hover {
  color: #0056b3;
}
.user-info {
  padding: 20px;
  background-color: #f9f9f9;
}
.user-info h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  cursor: pointer;
}
.user-info-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
}
.user-info-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.phoneNumber-section h2 {
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  margin-top: 20px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}
.modal-container .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-container h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}
.modal-container p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}
.modal-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.modal-container-1,
.modal-container-2-inner {
  margin-bottom: 15px;
}
.modal-container-2 {
  display: flex;
  gap: 15px;
}
.modal-container button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.modal-container button:hover {
  background-color: #0056b3;
}
.phone-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.phone-modal form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}
.phone-modal input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.phone-modal p {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  line-height: 1.5;
}
.phone-modal button {
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.phone-modal button:hover {
  background-color: #0056b3;
}
.error-message {
  background-color: red;
  color: black;
  font-size: 14px;
  margin-top: 10px;
  padding: 2%;
}
.membership {
  padding: 40px;
  background-color: #f5f7fa;
}
.membership-container {
  display: flex;
  gap: 30px;
  border-radius: 12px;
  background: linear-gradient(135deg, #ffffff 0%, #e3eaf5 100%);
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}
.membership-container-left {
  flex: 1;
}
.membership-container-left h1 {
  font-size: 28px;
  font-weight: 700;
  color: #00214d;
  margin-bottom: 20px;
}
.membership-container-perks {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}
.membership-container-perks li {
  font-size: 16px;
  color: #444;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
  transition: transform 0.2s ease;
}
.membership-container-perks li:hover {
  transform: translateX(5px);
}
.benefit-icon {
  color: #007bff;
  font-size: 20px;
}
.membership-container-left p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  margin-top: 25px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.membership-container-left span {
  color: #0056b3;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}
.membership-container-right {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.membership-container-right button {
  font-size: 20px;
  color: #fff;
  background: linear-gradient(135deg, #007bff, #0056b3);
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: bold;
}
.membership-container-right button:hover {
  background: linear-gradient(135deg, #0056b3, #003d7a);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 123, 255, 0.6);
}
.membership-active {
  width: 100%;
  color: whitesmoke;
}
.membership-active-container {
  text-align: center;
  margin: 0 auto;
}
.membership-active-header {
  background-color: #3c4755;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5%;
}
.perks-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.perk-item {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.benefit-icon {
  font-size: 1.8rem;
  color: #ff6f61;
}
.perk-item p {
  font-size: 1.3rem;
  color: #333;
  margin: 0;
}
.cancel-subscription {
  text-align: center;
}
.cancel-subscription .cancel-button {
  background-color: #ff6f61;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.cancel-subscription .message {
  margin-top: 15px;
  color: #555;
}
.cancel-subscription .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-subscription .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.cancel-subscription .modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.cancel-subscription .confirm-button,
.sub-close-button {
  background-color: #ff6f61;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.cancel-subscription .sub-close-button {
  background-color: #6c757d;
}
.refer-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.refer-title {
  font-size: 2rem;
  color: #333;
}
.refer-description {
  font-size: 1.2rem;
  margin: 10px 0 20px;
  color: #666;
}
.promo-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.promo-item {
  width: 45%;
}
.promo-item h2 {
  font-size: 1.5rem;
  color: #333;
}
.promo-item p {
  font-size: 1.2rem;
  color: #555;
}
.refer-code-section {
  margin-bottom: 20px;
}
.refer-code-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
input#referInput {
  width: 70%;
  padding: 10px;
  font-size: 1.2rem;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.copy-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.copy-btn:hover {
  background-color: #0056b3;
}
.share-section {
  margin-top: 30px;
}
.share-buttons {
  display: flex;
  justify-content: space-around;
}
.share-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.email-btn {
  background-color: #28a745;
  color: #fff;
}
.sms-btn {
  background-color: #17a2b8;
  color: #fff;
}
.social-btn {
  background-color: #007bff;
  color: #fff;
}
.refer-terms {
  font-size: 0.9rem;
  color: #999;
}
.past-orders {
  padding: 10px;
  background-color: #f5f5f5;
}
.past-orders-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}
.past-orders-container-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.past-orders .the-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  gap: 40px;
}
.past-orders .redirect-to-store {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  text-align: center;
  font-weight: 600;
}
.past-orders .redirect-to-store:hover {
  background-color: #45a049;
  transform: scale(1.05);
}
.past-orders .redirect-to-store:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.5);
}
.past-orders .redirect-to-store:active {
  background-color: #388e3c;
  transform: scale(1);
}
.past-orders .redirect-to-store:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}
.past-orders .the-order:last-child {
  border-bottom: none;
}
.past-orders .order-date h2 {
  font-size: 14px;
  color: #666;
  margin: 0;
  font-weight: normal;
}
.past-orders .the-order img {
  width: 150px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}
.order-restaurant h2 {
  font-size: 18px;
  margin: 0;
  color: #333;
}
.past-orders .the-order p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}
.past-orders .the-order div p {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}
.order-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.past-orders-container p {
  text-align: center;
  color: #888;
  font-size: 16px;
  margin-top: 20px;
}
.favorite {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2%;
  border: 1px solid;
}
.favorite img {
  width: 180px;
}
.favorite h1 {
  color: black;
}
.favorite-details-button {
  padding: 4%;
  height: 50px;
  width: 100px;
}
.favorite-details-button:hover {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .profile-categories-container {
    width: 30%;
    height: 110vh;
    padding: 0 2%;
  }
  .categories {
    padding: 0;
    height: 60%;
  }
  .profile-categories {
    height: auto;
  }
  .profile-category {
    margin: 1%;
  }
  .modal-container {
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 5%;
    margin: 1%;
  }
  .modal-container input {
    width: 100%;
    padding: 1.5%;
    border: 1px solid;
    border-radius: 5px;
  }
  .modal-container-2 {
    width: 100%;
  }
  .past-orders .the-order {
    flex-direction: column;
    gap: 10px;
  }
  .membership {
    padding: 2%;
  }
  .membership-container {
    flex-direction: column;
    gap: 20px;
  }
  .membership-container-left {
    width: 100%;
    border-right: none;
  }
  .membership-container-left h1 {
    font-size: 1.8rem;
  }
  .membership-container-left p {
    font-size: 0.9rem;
  }
  .membership-container-perks {
    padding: 0;
  }
  .membership-container-perks li {
    font-size: 1.1rem;
  }
  .membership-container-right button {
    padding: 10px;
    font-size: 2rem;
    width: 100%;
    margin-bottom: 3%;
  }
  .membership-container-left {
    gap: 0;
  }
  .membership-active-header {
    padding: 2%;
    flex-direction: column;
    text-align: center;
  }
  .perk-item {
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }
  .benefit-icon {
    font-size: 1.5rem;
  }
  .perk-item p {
    font-size: 1.2rem;
    text-align: center;
  }
  .perks-list {
    padding: 15px;
    gap: 15px;
  }
  .membership-container {
    width: 100%;
    padding: 0 1%;
  }
  .membership-container h1 {
    font-size: 3rem;
    text-align: center;
  }
  .cancel-subscription .cancel-button {
    font-size: 1.2rem;
  }
  .refer-title {
    font-size: 1.5rem;
  }
  .refer-description {
    font-size: 1rem;
  }
  .promo-info {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  .promo-item {
    width: 100%;
    margin-bottom: 15px;
  }
  .promo-item h2 {
    font-size: 1.2rem;
  }
  .promo-item p {
    font-size: 1rem;
  }
  input#referInput {
    width: 60%;
    margin-right: 5px;
  }
  .share-buttons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .share-btn {
    width: 100%;
    padding: 12px;
  }
  .refer-terms {
    font-size: 0.8rem;
    margin-top: 20px;
  }
  .favorite img {
    width: 60px;
  }
  .favorite h1 {
    font-size: 1.5rem;
  }
  .favorite-details-button {
    width: 80%;
    height: 40px;
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .membership-container-right button {
    padding: 8px;
    font-size: 1.8rem;
  }
  .favorite h1 {
    font-size: 1.5rem;
  }
  .favorite-details-button {
    font-size: 1rem;
  }
}
