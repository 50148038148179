.login {
  background-color: #fbfbfc;
  min-height: 70vh;
}
.login-form {
  background-color: whitesmoke;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 32px;
  margin: 2% auto;
  max-width: 450px;
  color: black;
}
.login-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 2%;
}
.login-form .userInput {
  width: 100%;
  padding: 2.6%;
  border-radius: 10px;
}
.login-form-actions {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.login-form-signIn-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.login-form-signIn-options button {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 1.5%;
  border-radius: 10px;
}
.error-message {
  padding: 2%;
  background-color: red;
  color: black;
  font-size: 14px;
  margin-top: 10px;
}
.login-form-actions p:hover {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .login {
    min-height: 65vh;
  }
  .login-form {
    margin: 1.5% auto;
    margin-top: 3%;
  }
}
