.letsEat {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;
}
.letsEatBlur {
  filter: blur(2px);
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.lets-eat-wrapper {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  margin-bottom: 3%;
}
.lets-eat-wrapper::-webkit-scrollbar {
  width: 8px;
}
.lets-eat-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.lets-eat-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.lets-eat-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.lets-eat-wrapper-h1 {
  padding-left: 3%;
  color: black;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: capitalize;
  position: sticky;
}
.lets-eat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lets-eat-container-1 {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 1%;
  padding: 1%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.lets-eat-container-1 > div {
  min-width: 150px;
  width: 200px;
  flex: 0 0 auto;
}
.lets-eat-container-1 > div:hover {
  cursor: pointer;
}
.lets-eat-container-1 h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  font-weight: 900;
  font-size: 1.3rem;
}
.lets-eat-container .electronic-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .health-and-wellness-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .books-and-educational-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .pets-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .jewlery-accessories-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .clothing-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .beauty-and-skincare-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .handmade-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container .home-goods-wrapper {
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #3e2723;
  background: linear-gradient(135deg, #f6f5f3, #eaeaea);
  color: #4a148c;
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}
.lets-eat-container-2::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.lets-eat-container-2 {
  overflow-x: auto;
  white-space: nowrap;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding-bottom: 2%;
}
.lets-eat-container-2 li {
  display: inline-block;
  padding: 1%;
}
.lets-eat-container-3 {
  margin: 0 2%;
  display: flex;
  gap: 20px;
}
.lets-eat-container-3 img {
  height: 150px;
  width: 300px;
  max-width: 300px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 20px;
  min-width: 200px;
}
.lets-eat-container-3 .details {
  display: flex;
  justify-content: space-between;
  padding: 1%;
}
.lets-eat-container-3 .inner {
  display: flex;
  flex-direction: column;
}
.lets-eat-container-restaurants .star {
  display: flex;
  align-items: center;
  gap: 10px;
}
.lets-eat-container-restaurants .image {
  cursor: pointer;
}
.details .inner h2 {
  font-weight: 900;
  color: black;
}
.all-stores-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 3%;
}
.all-stores h1 {
  padding-left: 3%;
  color: black;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: capitalize;
}
.modal-content {
  position: relative;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
.modal-text {
  font-size: 1.2rem;
  color: #666666;
  margin: 15px 0;
  line-height: 1.5;
}
.modal-button {
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.modal-button:hover {
  background-color: #45a049;
}
@media only screen and (max-width: 768px) {
  .lets-eat-container-3 img {
    height: 100px;
    width: 100%;
  }
  .lets-eat-container-3 h3 {
    font-size: 1.3rem;
  }
  .search-drop-down {
    width: 95%;
  }
  .search-drop-down-wrapper {
    width: 100%;
  }
  .search-drop-down-wrapper > * {
    padding: 5%;
  }
  .nav-bar-user-options {
    flex-direction: column;
    align-items: center;
  }
  .lets-eat-container-1 {
    margin: 0;
  }
}
