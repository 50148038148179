header {
  position: sticky;
  top: 0;
  z-index: 10000;
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  background-color: #bbbb04;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  padding: 1%;
}
.nav-bar-left {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  width: 45%;
  max-width: 45%;
}
.nav-bar-left Link {
  border: 1px solid green;
}
.nav-bar-right {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}
.nav-bar-address-container {
  border: 1px solid black;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  font-size: smaller;
  width: 60%;
  max-width: 60%;
}
.signedInSeach-container {
  width: 75%;
}
.signedIn-logo {
  border: 1px solid;
}
#signedInSeach {
  width: 100%;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.nav-bar-user-options {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  font-size: larger;
}
.nav-bar-user-options a {
  display: flex;
  align-items: center;
  color: rgb(54, 54, 54);
}
.cart-animation {
  display: flex;
  flex-direction: column;
  position: relative;
}
.circleExclamation {
  position: absolute;
  top: -10px;
  left: 5px;
}
.cart-icons-mobile{
    display: flex;
}
.search-drop-down {
  background-color: white;
  width: 520px;
  height: auto;
  position: fixed;
  border-radius: 10px;
  border: 1px solid;
}
.search-drop-down-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  font-size: 1.5rem;
}
.search-drop-down-wrapper > * {
  border-bottom: 1px solid rgb(228, 228, 228);
  padding: 1%;
}
.search-bar-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.search-bar-item p {
  font-size: 1.5rem;
  color: black;
  padding: 1%;
}
.search-bar-item img {
  width: 150px;
  max-width: 150px;
  height: 100px;
  max-height: 100px;
  border-radius: 10px;
}
.search-drop-down-wrapper > * :hover {
  background-color: rgb(200, 200, 200);
  transition: 0.3s;
  cursor: pointer;
}
.search-bar-down p {
  width: 35%;
}
.search-drop-down p:hover {
  background-color: rgb(200, 200, 200);
  cursor: pointer;
}
.search-drop-down-wrapper-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.close-search {
  text-align: center;
  color: black;
  font-size: larger;
  font-weight: 900;
}
@media only screen and (max-width: 768px) {
  header {
    background-color: #bbbb04;
  }
  .mobile-nav-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding: 1%;
  }
  .mobile-nav-bar img {
    width: 220px;
  }
  .mobile-nav-bar input {
    width: 95%;
  }
  .mobile-nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .mobile-nav-top .open {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  .mobile-nav-bottom {
    color: rgb(0, 0, 0);
    background-color: #b2b207;
    padding: 2% 0;
  }
  #signedInSearch {
    padding: 10px;
    border-radius: 10px;
  }
  .signedInSearch-container {
    text-align: center;
  }
  .mobile-address-container {
    display: flex;
    justify-content: space-between;
  }
  .mobile-nav-bar .mobile-show {
    position: absolute;
    top: 0;
    left: 0px;
    transform: translateX(0);
    transition: 0.7s;
    background-color: #f4f1de;
    height: 100vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    font-size: 1em;
  }
  .mobile-nav-bar .mobile-hide {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-200%);
    transition: 0.7s;
    background-color: #b2b207;
    height: 100vh;
    width: 70%;
  }
  .mobile-nav-bar .hamburger {
    transform: rotateZ(180deg);
    transition: 0.7s;
  }
  .mobile-nav-bar .hamburger-active {
    transform: rotateZ(-180deg);
    transition: 0.7s;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: -999;
  }
  .overlay-active {
    opacity: 1;
    visibility: visible;
  }
  .mobile-nav-bar-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-left: 3%;
  }
  .mobile-nav-bar-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .mobile-nav-bar-links a {
    color: #4f4f4f;
  }
  .mobile-nav-bar-container h2 {
    font-size: 1.6rem;
    font-weight: 900;
    color: black;
  }
  .search-drop-down {
    width: 420px;
    max-width: 420px;
  }
}
